<div class="zenmode-container">
  <div class="tips-container">
    <h1 class="title">{{ 'PAGES.ZENMODE.WELCOME.TITLE' | translate }}</h1>
    <h3 class="sub-title">{{ 'PAGES.ZENMODE.WELCOME.SUB_TITLE' | translate }}</h3>
    <p class="text">{{ 'PAGES.ZENMODE.WELCOME.DESCRIPTION' | translate }}</p>

    <div class="tips">
      @for (tipKey of tipKeys; track tipKey) {
        <div class="tip-item">
          <img [src]="'assets/icons/zenmode/tips/' + tipKey + '.svg'" alt="{{ tipKey }}">
          <p>{{ 'PAGES.ZENMODE.WELCOME_TIPS.TIPS.' + tipKey | translate }}</p>
        </div>
      }
    </div>

    <div class="action-container">
      <label class="checkbox-label">
        <input type="checkbox" [(ngModel)]="isDontShowAgain" />
        {{ 'PAGES.ZENMODE.WELCOME.DONT_SHOW_AGAIN' | translate }}
      </label>
      <button class="btn --blue start-btn" (click)="emitEvent()">{{ 'PAGES.ZENMODE.WELCOME.START' | translate }}</button>
    </div>


  </div>

  <div class="illustration-container">
    <img src="assets/img/zenmode-welcome.svg" alt="Zenmode Welcome Illustration">
  </div>
</div>
