<div class="zenmode-loading">
  <div class="logo-container">
    <img src="assets/icons/zenmode/logo.svg" alt="logo">
    <svg #progressBar xmlns="http://www.w3.org/2000/svg" width="159" height="20" viewBox="0 0 159 20">
      <rect x="0.5" y="2" width="158" height="16" rx="8" fill="#E1E9F5" filter="url(#shadow)"/>
      <rect [attr.width]="progressWidth" y="2" height="16" rx="8" fill="url(#progressGradient)" filter="url(#glow)"/>
      <defs>
        <linearGradient id="progressGradient" x1="0%" y1="50%" x2="100%" y2="50%">
          <stop offset="0%" stop-color="#B3D5FC"/>
          <stop offset="100%" stop-color="#3396F3"/>
        </linearGradient>
        <filter id="shadow" x="-5%" y="-5%" width="110%" height="110%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2"/>
          <feGaussianBlur result="blurOut" in="offOut" std="3"/>
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
        </filter>
        <filter id="glow">
          <feGaussianBlur stdDeviation="2.5" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
    </svg>
    <div class="advice-container">
      <img src="assets/icons/zenmode/idea.svg" alt="idea">
      <div class="advice-content">
        <div class="advice-title">
          {{ adviceKey + '.TITLE' | translate }}
        </div>
        @for (paragraph of (adviceKey + '.PARAGRAPHS' | translate); track paragraph) {
          <div class="advice-text">{{ paragraph }}</div>
        }
      </div>
    </div>
  </div>
</div>
