import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ZenmodeWelcomePopupContainerComponent } from '../modules/zenmode-welcome-popup-container/components/zenmode-welcome-popup-container/zenmode-welcome-popup-container.component';

@Injectable({
  providedIn: 'root',
})
export class ZenmodeWelcomePopupService {
  constructor(private dialog: MatDialog) {}

  open(): void {
    this.dialog.open(ZenmodeWelcomePopupContainerComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      maxHeight: '100vh',
      hasBackdrop: false,
      panelClass: 'zenmode-welcome-popup-container',
      disableClose: true,
    });
  }
}
