<div class="zenmode-welcome-popup">
  <div class="header">
    <div class="logo"><img src="assets/img/logo.svg" alt="logo"></div>
    <button (click)="manualClose()">
      <img src="assets/icons/zenmode/exit.svg" alt="exit">
    </button>
  </div>

  <div class="body">
    @switch (state) {
      @case (PopupState.WELCOME_TIPS) {
        <div>
          <app-zenmode-welcome-tips
            (startEvent)="handleStartEvent($event)"
          ></app-zenmode-welcome-tips>
        </div>
      }
      @case (PopupState.LOADING_TIP) {
        <div>
          <app-zenmode-welcome-loading
            (loadingCompleted)="handleLoadingCompleted()"
            >
          </app-zenmode-welcome-loading>
        </div>
      }
    }
  </div>
</div>
