import { NgClass } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DealsFacade } from '../../../../../../+state/deals/deals.facade';
import { ZenmodeWelcomePopupService } from '../../../../services/zenmode-welcome-popup.service';

@Component({
  selector: 'app-zenmode-toggle',
  templateUrl: './zenmode-toggle.component.html',
  styleUrls: ['./zenmode-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class ZenmodeToggleComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public isActive = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private zenmodeWelcomePopupService: ZenmodeWelcomePopupService,
    private dealsFacade: DealsFacade,
  ) {}

  toggle() {
    const isZenmodeOn = !this.isActive;
    if (isZenmodeOn) {
      this.dealsFacade.generateZenmodeList();
      this.zenmodeWelcomePopupService.open();
    } else {
      this.dealsFacade.toggleZenmode(isZenmodeOn);
    }
  }

  ngOnInit(): void {
    this.dealsFacade.zenmodeOn$.pipe(takeUntil(this.destroy$)).subscribe((isZenmodeOn: boolean) => {
      if (isZenmodeOn !== null) {
        this.isActive = isZenmodeOn;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
